import {useEffect, useState} from 'react';

import './index.css';

const Calculator = () => {
	const [mass, setMass] = useState(0);
	const [percent, setPercent] = useState(0);
	const [result, setResult] = useState(0);
	const [value, setValue] = useState(0);

	const uecFormat = new Intl.NumberFormat('en-US');

	useEffect(() => {
		setResult(Math.max(0, mass) * Math.max(0, percent) / 5000);
		setValue(Math.max(0, mass) * Math.max(0, percent) * 88 / 50);
	}, [mass, percent]);
	return <div className="verticalFlex">
		<label>Mass: <input type="text" onChange={(e) => setMass(e.target.value)}></input></label>
		<label>Percentage: <input type="text" onChange={(e) => setPercent(e.target.value)}></input></label>
		<label>{result.toFixed(2)} SCU</label>
		<label>{uecFormat.format(value)} aEUC</label>
	</div>;
};

export default Calculator;